import {ref, watch,} from "vue"
import {useRoute,} from "vue-router"

export const useActivatorTouchClick = ({activatorArray,}) => {
    const visibility = ref(activatorArray.map(()=>false))
    const route = useRoute()

    function show (index) {
        if(!globalThis.isTouchDevice) return
        visibility.value[index] = true
    }

    function hide (index) {
        if(!globalThis.isTouchDevice) return
        setTimeout(()=>{
            visibility.value[index] = false
        },1)
    }

    watch(route, () => {
        visibility.value = visibility.value.map(()=>false)
    })

    return {
        show,
        hide,
        visibility,
    }
}