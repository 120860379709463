<script setup>
import BsVerseReferences from '@/assets/js/src/modules/text/_components/VerseReferences.vue'
import {computed, toRefs,} from "vue"
import {useActivatorTouchClick,} from "@/assets/js/src/modules/text/_composables/useActivatorTouchClick"

const props = defineProps({
    verse: {
        type: Object,
        default: () => {},
    },
    bibleId: {
        type: Number,
        default: 0,
    },
    dir: {
        type: String,
        default: '',
    },
    verseStyle: {
        type: String,
        default: '',
    },
    verseFootnotes: {
        type: Array,
        default: () => [],
    },
    verseReferences: {
        type: Array,
        default: () => [],
    },
    verseReferencesGoogle: {
        type: String,
        default: '',
    },
    noSelection: {
        type: Boolean,
        default: false,
    },
})

defineEmits([ 'select-verse', ])

const {verse,verseFootnotes,noSelection,} = toRefs(props)

const getAllCanonicalClasses = computed(()=>{
    let range = (verse.value.verse_end || verse.value.verse ) - verse.value.verse
    let canonicals = `v${verse.value.canonical} v${verse.value.verse}`
    for (let i = 0; i < range; i++) {
        canonicals += ` v${verse.value.canonical + i + 1} v${verse.value.verse + i + 1}`
    }

    return canonicals
})

const {hide,show,visibility,} = useActivatorTouchClick({
    activatorArray: verseFootnotes.value,
})

const shouldClickEvent = computed(()=>{
    return noSelection.value ? undefined : 'click'
})
</script>

<template>
    <span
        :id="'v_' + verse.id"
        :data-vid="verse.id"
        :class="[
            'verse',
            'scroll-elem',
            getAllCanonicalClasses,
        ]"
        :style="verseStyle"
    ><span class="d-sr-only">&#x2;</span><span
        class="verse-number"
    ><span
        class="verse-number__group"
        @[shouldClickEvent]="$emit('select-verse', $event)"
    ><span>{{ verse.verse }}</span><template v-if="verse.verse_end"><span style="line-height: 0">-</span><span>{{ verse.verse_end }}</span></template></span><span>&nbsp;</span></span><!--
        ### Vers-Inhalt ###
     --><span
            class="verse-content"
            @[shouldClickEvent]="$emit('select-verse', $event)"
        ><!--
            Vers-Inhalt ohne Fußnoten
         --><span class="verse-content--hover">
                <template v-if="verseFootnotes.length === 0">{{ verse.content }}</template>
                <template v-else><template v-for="(versePart, index) in verseFootnotes"><v-tooltip
                            v-if="typeof versePart.number !== 'undefined'"
                            :key="'fn_'+versePart.number"
                            v-model="visibility[index]"
                            attach="#teleported"
                            offset="8"
                            :close-delay="600"
                            location="bottom"
                        ><template #activator="{ props: tooltipProps, }"><sup
                            class="footnote"
                            v-bind="tooltipProps"
                            @click.stop.prevent="show(index)"
>{{ versePart.number }}</sup></template><span
                                :data-bible="bibleId"
                                :dir="dir"
                                class="footnote-content"
                            ><v-icon
                    tag="button"
                    @click.stop.prevent="() => hide(index)"
                >bs:$vuetify.icons.mdiClose</v-icon><span
                                    class="d-sr-only d-print"
                                >{{ (index - index % 2) / 2 + 1 }}.&nbsp;</span>{{ versePart.content }}</span></v-tooltip><template v-else>{{ versePart.content }}</template></template></template></span><!-- Verse References
         --><bs-verse-references
                :verse-references="verseReferences"
                :ref-id="`${bibleId}_${verse.id}`"
                :dir="dir"
            /><noscript
            v-if="verseReferences.length !== 0"
            title="Crossrefs"
            v-html="verseReferencesGoogle"
        ></noscript></span><span class="d-sr-only">&#x3;</span><span>&ensp;</span></span>
</template>
