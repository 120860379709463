<script setup>
import {useActivatorTouchClick,} from "@/assets/js/src/modules/text/_composables/useActivatorTouchClick"
import {toRefs,} from "vue"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

const componentProps = defineProps({
    verseReferences: {
        type: Array,
        default: () => [],
    },
    refId: {
        type: String,
        default: '',
    },
    dir: {
        type: String,
        default: '',
    },
})

const {verseReferences,} = toRefs(componentProps)

const {hide,show,visibility,} = useActivatorTouchClick({
    activatorArray: verseReferences.value,
})
const {t,} = useTranslation()
</script>

<template>
    <span
        v-if="verseReferences.length !== 0"
        class="verse-references"
    ><!--
     --> (<!--
     --><template
        v-for="(ref, ref_index) in verseReferences"
        :key="`ref_${refId}_${ref_index}`"
    >
        <v-tooltip
            v-model="visibility[ref_index]"
            class="crossref-tooltip"
            offset="8"
            :close-delay="200"
            location="bottom"
            attach="#teleported"
    ><!--
	 --><template #activator="{ props }">
	    <span v-bind="props"><!--
		--><a
            :href="ref.link"
            class="ref-link"
            @click.stop.prevent="show(ref_index)"
        ><!--
             -->{{ ref.link_by_canonical }}<!--
	 --></a></span></template><span
            :dir="dir"
            class="footnote-content"
        ><v-icon
            tag="button"
            @click.stop.prevent="hide(ref_index)"
        >bs:$vuetify.icons.mdiClose</v-icon><strong><router-link
            :to="{path: ref.link}"
            class="text-white"
        >{{ ref.link_by_canonical }}</router-link></strong>&nbsp;{{ ref.content }}&nbsp;<router-link
            :to="{path: `/selectedbibles${ref.parts?.href}`}"
            class="text-white"
        >{{ t('async.common.button.more') }}</router-link></span></v-tooltip><!--
            Notwendig, damit kein Leerzeichen zwischen den Elementen entsteht
         -->{{ ref_index === verseReferences.length - 1 ? '' : '; ' }}<!--
     --></template>)<!--
 --></span>
</template>
